import Swiper from 'swiper';
import {
    Navigation,
    EffectFade,
    Pagination,
    Autoplay,
    Scrollbar
} from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/autoplay';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import 'swiper/scss/virtual';

Swiper.use([Navigation, EffectFade, Pagination, Autoplay, Scrollbar]);

import {
    gsap
} from "gsap";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";

document.addEventListener("DOMContentLoaded", function () {

    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollTrigger);

    document.querySelectorAll(".title").forEach((title) => {
        let mySplitText = new SplitText(title, { type: "lines" });
        let lines = mySplitText.lines;
    
        gsap.set(title, { perspective: 450 });
    
        gsap.from(lines, {
            yPercent: 100,
            autoAlpha: 0,
            duration: 1, 
            stagger: 0.1,
            ease: 'power2.out',
            scrollTrigger: {
                toggleActions: 'play none none none',
                trigger: title, // Ogni titolo ha il proprio trigger
                start: 'top 80%',
            },
        });
    });

    let anim = gsap.to('.scrub-text', {
        xPercent: -100,
    })

    
    ScrollTrigger.create({
    trigger: '.scrub-text',
    scrub: 2,
    animation: anim
    });


    gsap.utils.toArray(".fadein").forEach((fadeinElement) => {

        gsap.from(fadeinElement, {
          opacity: 0,
          y: 0,  
          duration: 2,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: fadeinElement,
            start: "top 70%",      
            toggleActions: "play none none none",
          }
        });
      });
    
      gsap.from(".fadein-1", {
        opacity: 0,
        y: 0,
        duration: 1.2,
        delay: 0.6,
        scrollTrigger: {
          trigger: ".fadein-1",
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none none",
        }
      });
    
      gsap.from(".fadein-2", {
        opacity: 0,
        y: 0,
        duration: 1.4,
        delay: 1.3, 
        scrollTrigger: {
          trigger: ".fadein-2",
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none none",
        }
      });


    if (window.innerWidth > 1020) {
        var rellax = new Rellax('.rellax');
    }

    const swiper = new Swiper('.swiper-text', {
        centeredSlides: true,
        speed: 10000,
        allowTouchMove: false,
        slidesPerView: 0.5,
        breakpoints: {
            768: {
                slidesPerView: 'auto',
                spaceBetween: 30,
            },

        },
        loop: true,
        autoplay: {
            delay: 0,
        },

    });

    const swipercards = new Swiper('.swiper-cards', {
        allowTouchMove: false,
        slidesPerView: 1.1,
        spaceBetween: 20,
        breakpoints: {
            1000: {
                slidesPerView: 2.3,
                spaceBetween: 50,
            },

            1300: {
                slidesPerView: 3.1,
                spaceBetween: 50,
            },
            1540: {
                slidesPerView: '3.5',
                spaceBetween: 50,
            },
        },
        loop: false,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        allowTouchMove: true,
    });

// Funzione per throttling (riduce il numero di esecuzioni della funzione)
function throttle(func, limit) {
    let inThrottle;
    return function() {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }
  
  let lastScrollTop = 0;
  
  window.addEventListener(
    'scroll',
    throttle(function () {
      var scrolltop = window.pageYOffset || document.documentElement.scrollTop;
      
        if (scrolltop < lastScrollTop) {
            document.querySelector('header').classList.remove('move-height');
            document.getElementById('block-image').classList.add('not-hero');
        } else {
          document.querySelector('header').classList.add('move-height');
          document.getElementById('block-image').classList.add('not-hero');
        }

        
        if (scrolltop === 0) {
            document.getElementById('block-image').classList.remove('not-hero');
          setTimeout(function () {
            document.querySelector('header').classList.remove('move-height');
          }, 250);
        }

        if (scrolltop < 300) {
            document.getElementById('block-image').classList.remove('not-hero');
        }
  
      lastScrollTop = scrolltop;
    }, 100)
  );
  


    var colors = [
        [184, 43, 255],  // #B82BFF
        [79, 171, 191],  // #4FABBF
        [57, 255, 154]   // #39FF9A
    ];

    var step = 0;
    var colorIndices = [0, 1, 2];

    var gradientSpeed = 0.002;

    function updateGradient() {
        var c0 = colors[colorIndices[0]];
        var c1 = colors[colorIndices[1]];
        var c2 = colors[colorIndices[2]];

        var istep = 1 - step;
        
        var r1 = Math.round(istep * c0[0] + step * c1[0]);
        var g1 = Math.round(istep * c0[1] + step * c1[1]);
        var b1 = Math.round(istep * c0[2] + step * c1[2]);
        var color1 = `rgb(${r1},${g1},${b1})`;

        var r2 = Math.round(istep * c1[0] + step * c2[0]);
        var g2 = Math.round(istep * c1[1] + step * c2[1]);
        var b2 = Math.round(istep * c1[2] + step * c2[2]);
        var color2 = `rgb(${r2},${g2},${b2})`;

        var gradient = `linear-gradient(125deg, ${color1}, ${color2})`;
        document.getElementById("gradient").style.background = gradient;

        step += gradientSpeed;
        if (step >= 1) {
            step %= 1;
            colorIndices[0] = colorIndices[1];
            colorIndices[1] = colorIndices[2];
            colorIndices[2] = (colorIndices[2] + Math.floor(1 + Math.random() * (colors.length - 1))) % colors.length;
        }
    }

    setInterval(updateGradient, 10);


});

class WebGL {
    constructor(container = document.getElementById('cards')) { // Specifica il div con ID 'cards'
        this.container = container; // Imposta il container
        this.setup();
        this.camera();
        this.addObjects();
        this.eventListeners();
        this.settings();
        this.animate();
        this.render();
    }

    settings() {
        this.settings = {
            blur: 0.05,
            speed: 0.1,
            noiseFreq: 0.3
        };
    }

    setup() {
        this.clock = new THREE.Clock();
        this.mouse = new THREE.Vector2();
        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({
            antialias: true
        });
        this.renderer.setSize(this.viewport.width, this.viewport.height);
        this.renderer.setPixelRatio = window.devicePixelRatio;
        this.renderer.setClearColor(0xffffff, 1);
        this.container.appendChild(this.renderer.domElement);
    }

    camera() {
        const FOV = 50;
        const NEAR = 1;
        const FAR = 10;
        const ASPECT_RATIO = this.viewport.aspectRatio;

        this.camera = new THREE.PerspectiveCamera(FOV, ASPECT_RATIO, NEAR, FAR);
        this.camera.position.set(0, 0, 10);
    }

    addObjects() {
        this.time = 0;
        this.geometry = new THREE.PlaneBufferGeometry(2, 2, 16, 16);
        this.material = new THREE.ShaderMaterial({
            uniforms: {
                u_time: {
                    type: "f",
                    value: 0
                },
                u_resolution: {
                    type: "v4",
                    value: new THREE.Vector4()
                },
                u_aspect: {
                    type: "f",
                    value: this.aspectRatio
                },
                u_noiseFreq: {
                    value: this.settings.blur
                },
                u_progress: {
                    value: 0
                },
                blur: {
                    value: this.settings.blur
                },
                speed: {
                    value: this.settings.speed
                }
            },
            transparent: true,
            wireframe: false,
            vertexShader: document.getElementById("vertex").textContent,
            fragmentShader: document.getElementById("fragment").textContent
        });

        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.mesh.position.set(0, 0, 0);
        this.scene.add(this.mesh);
    }

    animate() {
        const tl = gsap.timeline();

        if (window.innerWidth < 1200) {
            tl.to(this.material.uniforms.u_progress, {
                duration: 2,
                value: 1.0,
                ease: "power3.out"
            }).to(
                this.camera.position, {
                    z: 3,
                    duration: 2,
                    ease: "power3.out"
                },
                0
            );
        } else {
            tl.to(this.material.uniforms.u_progress, {
                duration: 2,
                value: 1.0,
                ease: "power3.out"
            }).to(
                this.camera.position, {
                    z: 2.5,
                    duration: 2,
                    ease: "power3.out"
                },
                0
            );
        }
    }

    render() {
        this.camera.lookAt(this.scene.position);
        this.renderer.render(this.scene, this.camera);

        this.material.uniforms.u_time.value = this.clock.getElapsedTime();
        this.material.uniforms.blur.value = this.settings.blur;
        this.material.uniforms.speed.value = this.settings.speed;
        this.material.uniforms.u_noiseFreq.value = this.settings.noiseFreq;

        requestAnimationFrame(() => {
            this.render();
        });
    }

    eventListeners() {
        window.addEventListener("resize", this.onWindowResize.bind(this));
    }

    onWindowResize() {
        this.camera.aspect = this.viewport.aspectRatio;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.viewport.width, this.viewport.height);
    }

    get viewport() {
        const width = window.innerWidth * 0.99; // 50% della larghezza della finestra
        const height = window.innerWidth * 0.99; // 50% della larghezza della finestra (per avere un layout quadrato)
        const aspectRatio = width / height;

        this.aspectRatio = aspectRatio;

        return {
            width,
            height,
            aspectRatio
        };
    }

}

const canvas1 = new WebGL();
const canvas2 = new WebGL();